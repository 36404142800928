import { pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: pxToRem(52),
  },
  heading: {
    marginBottom: pxToRem(36),
  },
  loginButton: {
    marginTop: pxToRem(24),
    marginBottom: pxToRem(12),
  },
  cantLogin: {
    alignSelf: "center",
    marginBottom: pxToRem(40),
  },
  font: {
    fontSize: pxToRem(18),
  },
  logo: {
    width: "100%",
    height: "100%",
  },
});

export default useStyles;
