import { Col, Row, Text } from "@gada-saas/web-ui";
import { Divider } from "@material-ui/core";
import { EmailOutlined, PhoneOutlined, WhatsApp } from "@material-ui/icons";
import Link from "next/link";
import * as React from "react";
import useStyles from "./styles";

const CantLoginModalContent: React.VFC = () => {
  const classes = useStyles();

  const goToWhatsApp = React.useMemo(() => "https://wa.me/628196009999", []);

  const goToCall = React.useMemo(() => "tel:621500019", []);

  const goToEmail = React.useMemo(() => "mailto:hello@gudangada.com", []);

  return (
    <Col>
      <Text>
        Jika Anda mengalami kendala karena{" "}
        <span className={classes.bold}>
          handphone hilang, nomor sudah ganti
        </span>{" "}
        atau <span className={classes.bold}>tidak aktif</span>, segera hubungi
        tim kami untuk dapat mengakses akun Anda
      </Text>

      <Row id="link_whatsapp" className={classes.row}>
        <WhatsApp className={classes.icon} />
        <Col>
          <Text variant="body2" ink="neutralPrimary" className={classes.medium}>
            WhatsApp
          </Text>
          <Link href={goToWhatsApp} passHref>
            <Text className={classes.hover} variant="h4">
              +62 819 600 9999
            </Text>
          </Link>
        </Col>
      </Row>

      <Divider />

      <Row id="link_phone" className={classes.row}>
        <PhoneOutlined className={classes.icon} />
        <Col>
          <Text variant="body2" ink="neutralPrimary" className={classes.medium}>
            No. Telepon
          </Text>
          <Link href={goToCall} passHref>
            <Text className={classes.hover} variant="h4">
              +621 500 019
            </Text>
          </Link>
        </Col>
      </Row>

      <Divider />

      <Row id="link_email" className={classes.row}>
        <EmailOutlined className={classes.icon} />
        <Col>
          <Text variant="body2" ink="neutralPrimary" className={classes.medium}>
            Email
          </Text>
          <Link href={goToEmail} passHref>
            <Text className={classes.hover} variant="h4">
              hello@gudangada.com
            </Text>
          </Link>
        </Col>
      </Row>

      <Divider />
    </Col>
  );
};

export default CantLoginModalContent;
