import ErrorMessages from "@common/constants/messages";
import { PHONE_NUMBER_ONLY_STRING_REGEX } from "@common/constants/regex";
import * as Yup from "yup";
import { FormValues } from "./types";

export const formInitialValues = (queryPhoneNumber: string): FormValues => {
  return { phoneNumber: queryPhoneNumber ?? "" };
};

export const loginValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(
      PHONE_NUMBER_ONLY_STRING_REGEX,
      "Nomor handphone harus 9 hingga 12 digit angka"
    )
    .required(ErrorMessages.required("Nomor handphone")),
});
