import { BlacklistedModalContent, Logo } from "@auth/components";
import { StyledAuthFormWrapper } from "@auth/components/styles";
import OtpFlowModal from "@auth/login/components/OtpFlowModal";
import { withLoginRedirection } from "@common/hocs/withLoginRedirection.tsx";
import AuthLayout from "@common/layouts/AuthLayout";
import { VerificationType } from "@gada-saas/web-core";
import { Button, Modal, PhoneField, Row, Text } from "@gada-saas/web-ui";
import { Box } from "@material-ui/core";
import { showFieldError } from "@utils/form";
import * as React from "react";
import CantLoginModalContent from "./components/CantLoginModalContent";
import useStyles from "./styles";
import useLogin from "./useLogin";

const Login: React.VFC = () => {
  const classes = useStyles();

  const {
    formik,
    cantLoginModal,
    otpFlowModal,
    unregisteredModal,
    blacklistedModal,
    handleLoginSuccess,
    goToRegisterFlow,
  } = useLogin();

  return (
    <AuthLayout>
      <StyledAuthFormWrapper>
        <Logo />

        <Text variant="h1" className={classes.heading}>
          Login
        </Text>

        <PhoneField
          id="input_phone_number"
          name="phoneNumber"
          label="Nomor Handphone"
          value={formik.values.phoneNumber}
          errorText={showFieldError(
            formik.touched.phoneNumber,
            formik.errors.phoneNumber
          )}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Button
            id="button_masuk"
            variant="primary"
            className={classes.loginButton}
            onClick={() => formik.handleSubmit()}
          >
            Masuk
          </Button>

          <Button
            id="button_phone_not_active"
            variant="text"
            className={classes.cantLogin}
            onClick={cantLoginModal.toggle}
          >
            <Text className={classes.font} variant="button" ink="tealPrimary">
              No. Saya Sudah Ganti/Tidak Aktif
            </Text>
          </Button>
        </Box>

        <Row hCenter>
          <Text className={classes.font}>Belum punya akun?</Text>
          <Button
            id="button_register"
            variant="text"
            onClick={goToRegisterFlow}
          >
            <Text variant="button" ink="tealPrimary" className={classes.font}>
              Daftar di Sini
            </Text>
          </Button>
        </Row>
      </StyledAuthFormWrapper>

      <OtpFlowModal
        verificationType={VerificationType.LOGIN}
        openOtpFlowModal={otpFlowModal.value}
        setOtpFlowModal={otpFlowModal.set}
        toggleOtpFlowModal={otpFlowModal.toggle}
        phoneNumber={formik.values.phoneNumber}
        openUnregisteredModal={unregisteredModal.toggle}
        openBlackListedModal={blacklistedModal.toggle}
        handleNext={handleLoginSuccess}
      />

      <Modal
        withCloseButton
        open={cantLoginModal.value}
        title="Nomor Ganti/Tidak Aktif"
        content={<CantLoginModalContent />}
        onClickCloseButton={cantLoginModal.toggle}
      />

      <Modal
        open={unregisteredModal.value}
        title="Apakah Anda Ingin Mendaftar?"
        content="Nomor handphone yang Anda masukkan belum terdaftar"
        primaryButtonText="Ya, Daftar"
        onClickPrimaryButton={goToRegisterFlow}
        secondaryButtonText="Tidak"
        onClickSecondaryButton={unregisteredModal.toggle}
      />

      <Modal
        open={blacklistedModal.value}
        title="Nomor Handphone Tidak Terdaftar"
        content={<BlacklistedModalContent />}
        primaryButtonText="Oke, Mengerti"
        onClickPrimaryButton={blacklistedModal.toggle}
      />
    </AuthLayout>
  );
};

export default withLoginRedirection(Login);
