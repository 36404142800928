import { PAGES } from "@common/constants";
import { useModalState, useQueryParams } from "@common/hooks";
import { useLazyGetUserInfoQuery } from "@gada-saas/web-core";
import { useFormik } from "formik";
import { useOfflineInitialDataFetcher } from "@miscellaneous/offline/components/OfflineInitialDataFetcher/useOfflineIniitalDataFetcher";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import router from "next/router";
import React from "react";
import { formInitialValues, loginValidationSchema } from "./forms";

const useLogin = () => {
  const { phoneNumber: queryPhoneNumber } = useQueryParams();
  const analytics = useSegmentAnalytics();
  const [cantLoginModal, toggleCantLoginModal] = useModalState();
  const [otpFlowModal, toggleOtpFlowModal, setOtpFlowModal] = useModalState();
  const [unregisteredModal, _, setUnregisteredModal] = useModalState();
  const [blacklistedModal, _b, setBlackListedModal] = useModalState();

  const [lazyGetUserInfoQuery] = useLazyGetUserInfoQuery();
  const { fetchAllInitialData } = useOfflineInitialDataFetcher();

  React.useEffect(() => {
    if (cantLoginModal) {
      analytics.track("Contact Us");
    }
    if (unregisteredModal) {
      analytics.track("User Does Not Exist");
    }
  }, [analytics, cantLoginModal, unregisteredModal]);

  const handleSubmit = React.useCallback(() => {
    analytics.track("Login Attempted");
    setOtpFlowModal(true);
  }, [analytics, setOtpFlowModal]);

  const formik = useFormik({
    initialValues: formInitialValues(queryPhoneNumber),
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
  });

  const toggleUnregisteredModal = React.useCallback(() => {
    setUnregisteredModal((unregisteredModal) => !unregisteredModal);
  }, [setUnregisteredModal]);

  const toggleBlacklistedModal = React.useCallback(() => {
    setBlackListedModal((blacklistedModal) => !blacklistedModal);
  }, [setBlackListedModal]);

  const goToRegisterFlow = React.useCallback(() => {
    setUnregisteredModal(false);
    router.push(
      `${PAGES.REGISTRATION}?phoneNumber=${formik.values.phoneNumber}`
    );
  }, [formik.values.phoneNumber, setUnregisteredModal]);

  const handleLoginSuccess = React.useCallback(() => {
    analytics.track("Login Completed");
    lazyGetUserInfoQuery();
    fetchAllInitialData();
    router.push(PAGES.HOME);
  }, [analytics, fetchAllInitialData, lazyGetUserInfoQuery]);

  return {
    formik: {
      values: formik.values,
      touched: formik.touched,
      errors: formik.errors,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      handleSubmit: formik.handleSubmit,
    },
    cantLoginModal: {
      value: cantLoginModal,
      toggle: toggleCantLoginModal,
    },
    otpFlowModal: {
      value: otpFlowModal,
      set: setOtpFlowModal,
      toggle: toggleOtpFlowModal,
    },
    unregisteredModal: {
      value: unregisteredModal,
      toggle: toggleUnregisteredModal,
    },
    blacklistedModal: {
      value: blacklistedModal,
      toggle: toggleBlacklistedModal,
    },
    handleLoginSuccess,
    goToRegisterFlow,
  };
};

export default useLogin;
