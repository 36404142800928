import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  bold: {
    fontWeight: "bold",
  },
  row: {
    margin: `${pxToRem(16)} 0`,
  },
  icon: {
    color: theme.palette.interface.neutral.primary,
    marginRight: pxToRem(16),
    width: pxToRem(24),
    height: pxToRem(24),
  },
  medium: {
    marginBottom: pxToRem(8),
  },
  hover: {
    cursor: "pointer",
  },
}));

export default useStyles;
